<div class="flex">
    <div class="container-list">
        <h3>Direktnachrichten</h3>
        <!-- Updated search input field -->
        <div class="search-container">
            <input type="text" pInputText [(ngModel)]="searchQuery" (input)="onSearch()" placeholder="Suche nach Gesprächen oder Benutzern...">
        </div>
        <!-- Updated search results (always shown when searching) -->
        <ul *ngIf="searchQuery && searchQuery.length > 0" class="search-results">
            <li *ngFor="let result of searchResults" (click)="selectSearchResult(result)">
                <span [ngStyle]="{'color': isExistingConversation(result) ? 'blue' : 'black'}">
                    {{ result.name }}
                </span>
            </li>
        </ul>
        <!-- Skeleton loader for direct conversations -->
        <ul *ngIf="isLoading" class="conversation-list">
            <li *ngFor="let i of [1,2,3,4,5]" class="skeleton-item">
                <div class="skeleton-name"></div>
                <div class="skeleton-message"></div>
                <div class="skeleton-timestamp"></div>
            </li>
        </ul>
        <!-- Conversation list (shows filtered results when searching) -->
        <ul *ngIf="!isLoading" class="conversation-list">
            <li *ngFor="let conversation of filteredDirectConversations" 
                (click)="selectConversation(conversation)">
                <div class="conversation-item" [ngClass]="{'selected': conversation.conversationId === selectedConversationId}">
                    <span class="name">{{ conversation.otherParticipantName }}</span>
                    <span class="last-message">{{ conversation.lastMessage ? ((conversation.lastMessage | slice:0:30) + (conversation.lastMessage.length > 30 ? '...' : '')) : 'Keine Nachrichten' }}</span>
                    <span class="timestamp">{{ conversation.lastMessageTimeStamp ? (conversation.lastMessageTimeStamp | date:'dd.MM.yyyy HH:mm') : 'Kein Datum' }}</span>
                    <span class="new-message" *ngIf="conversation.numberOfNewMessages > 0">{{ conversation.numberOfNewMessages }}</span>
                </div>
            </li>
        </ul>
        <h3>Projektgespräche</h3>
        <!-- Add this new search input for project conversations -->
        <div class="search-container">
            <input type="text" pInputText [(ngModel)]="projectSearchQuery" (input)="onProjectSearch()" placeholder="Suche nach Projekten...">
        </div>
        <!-- Skeleton loader for project conversations -->
        <ul *ngIf="isLoading" class="conversation-list">
            <li *ngFor="let i of [1,2,3]" class="skeleton-item">
                <div class="skeleton-name"></div>
                <div class="skeleton-participants"></div>
            </li>
        </ul>
        <ul *ngIf="!isLoading" class="conversation-list">
            <li *ngFor="let conversation of filteredProjectConversations">
                <div class="project-conversation-item" (click)="toggleProjectConversation(conversation)">
                    <span class="name" [title]="conversation.producerName">{{ conversation.producerName }}</span>
                    <span class="participants">{{ getTotalParticipants(conversation) }} Teilnehmer</span>
                    <span class="new-message" *ngIf="getProjectNewMessageCount(conversation) > 0">{{ getProjectNewMessageCount(conversation) }}</span>
                    <i class="pi" [ngClass]="{'pi-chevron-down': !conversation.isExpanded, 'pi-chevron-up': conversation.isExpanded}"></i>
                </div>
                <div *ngIf="conversation.isExpanded" class="project-conversation-details">
                    <h4>Rollen</h4>
                    <ul class="roles-list">
                        <li *ngFor="let role of conversation.roles" class="role-item">
                            <div class="role-header" (click)="toggleRole(role)" style="cursor: pointer;">
                                <i class="pi pi-users"></i>
                                <span class="role-name">{{ role.name }}</span>
                                <i class="pi" [ngClass]="{'pi-chevron-down': !role.isExpanded, 'pi-chevron-up': role.isExpanded}" style="margin-left: 5px; position: relative; top: 1px;"></i>
                                <span class="new-message" *ngIf="getRoleNewMessageCount(role) > 0">{{ getRoleNewMessageCount(role) }}</span>
                            </div>
                            <ul *ngIf="role.isExpanded" class="participants-list">
                                <li *ngFor="let participant of role.participants" class="participant-item" 
                                    [ngClass]="{
                                        'no-account': !participant.oauthId, 
                                        'selected': participant.oauthId && participant.oauthId === selectedParticipantId
                                    }"
                                    [pTooltip]="!participant.oauthId ? 'Benutzerkonto erforderlich' : ''"
                                    (click)="selectParticipant(participant, conversation.projectId, role.roleId)">
                                    <i class="pi" [ngClass]="{'pi-user': participant.oauthId, 'pi-user-minus': !participant.oauthId}"></i>
                                    <span>{{ participant.name || 'Unbenannter Benutzer' }}</span>
                                    <i *ngIf="!participant.oauthId" class="pi pi-exclamation-circle" style="color: orange; margin-left: 5px; position: relative; top: 2px;"></i>
                                    <span class="new-message" *ngIf="participant.numberOfNewMessages > 0">{{ participant.numberOfNewMessages }}</span>
                                </li>
                            </ul>
                            <button pButton type="button" label="Nachricht an alle senden" 
                                    (click)="openBroadcastInterface(role, conversation.projectId)"
                                    class="p-button-text" style="font-size: 12px; text-align: left;">
                            </button>
                        </li>
                    </ul>
                    <div *ngIf="conversation.additionalParticipants.length > 0">
                        <h4>Zusätzliche Teilnehmer</h4>
                        <ul class="participants-list">
                            <li *ngFor="let participant of conversation.additionalParticipants" class="participant-item"
                                [ngClass]="{
                                    'no-account': !participant.oauthId, 
                                    'selected': participant.oauthId && participant.oauthId === selectedParticipantId
                                }"
                                [pTooltip]="!participant.oauthId ? 'Benutzerkonto erforderlich' : ''"
                                (click)="selectParticipant(participant, conversation.projectId)">
                                <i class="pi" [ngClass]="{'pi-user': participant.oauthId, 'pi-user-minus': !participant.oauthId}"></i>
                                <span>{{ participant.name || 'Unbenannter Benutzer' }}</span>
                                <i *ngIf="!participant.oauthId" class="pi pi-exclamation-circle" style="color: orange; margin-left: 5px; position: relative; top: 2px;"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="container-chat">
        <app-conversation-chat 
            *ngIf="selectedConversation !== null && !showNoAccountMessage && !showBroadcastInterface"
            [isVisible]="true"
            [conversationId]="selectedReceiverConversationId" 
            [messageList]="messageList"
            [currentUserOauthId]="currentUserOauthId" 
            [isGroupChat]="false" 
            [shouldScrollToBottom]="true"
            [onSendMessage]="onSendMessage.bind(this)" 
            [onDeleteMessage]="onDeleteMessage.bind(this)"
            [onCameraButtonClick]="onCameraButtonClick.bind(this)"
            [onRefreshMessages]="onRefreshMessages.bind(this)"
            [projectId]="selectedProjectId">
        </app-conversation-chat>
        <div *ngIf="showNoAccountMessage" class="no-account-message">
            <h3>Gespräch kann nicht gestartet werden</h3>
            <p>Der ausgewählte Teilnehmer hat noch kein Benutzerkonto. Er muss ein Konto erstellen, bevor Sie ihm eine Nachricht senden können.</p>
            <p>Bitte wählen Sie einen anderen Teilnehmer aus oder warten Sie, bis dieser Benutzer ein Konto erstellt hat.</p>
        </div>
        <div *ngIf="showBroadcastInterface" class="broadcast-interface">
            <h3>Nachricht an alle senden</h3>
            <p>Rolle: {{ selectedRole?.name }}</p>
            <div class="p-fluid">
                <div class="p-field">
                    <label for="broadcastMessage">Nachricht</label>
                    <textarea id="broadcastMessage" pInputTextarea [(ngModel)]="broadcastMessage" rows="5" style="width: 100%;"></textarea>
                </div>
            </div>
            <div class="button-container">
                <button pButton type="button" label="Abbrechen" (click)="showBroadcastInterface = false" class="p-button-text"></button>
                <button pButton type="button" label="Senden" (click)="sendBroadcastMessage()" class="p-button-text"></button>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>
