import {AfterViewChecked, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MessageDto} from "@brody-bookings/api";
import { MessageResponseDto } from '@brody-bookings/api-v2';
import { MessageDtoWithDeletedFlag } from '../../shared/widgets/conversation/conversation.widget.component';

@Component({
    selector: 'app-conversation-chat',
    templateUrl: './conversationChat.component.html',
    styleUrls: ['./conversationChat.styles.scss']
})
export class ConversationChatComponent implements AfterViewChecked, OnChanges {
    newMessage = '';
    @Input() isVisible = false;
    @Input() messageList!: MessageDtoWithDeletedFlag[];
    @Input() conversationId!: string | undefined;
    @Input() onSendMessage!: (newMessage: string, images: Array<Blob>, projectId?: string) => Promise<any> | undefined;
    @Input() onDeleteMessage!: (messageId: string) => void;
    @Input() isGroupChat!: boolean;
    @Input() currentUserOauthId!: string;
    @ViewChild("messageContainer") messageContainer!: ElementRef;
    @Input() shouldScrollToBottom!: boolean; // Flag to track auto-scrolling
    @Input() onCameraButtonClick!: () => void;
    @Input() onRefreshMessages!: () => void; // New Input for refreshing messages
    @Input() projectId?: string;
    selectedFiles: Blob[] = [];
    thumbnails: string[] = [];
    @ViewChild('fileInput') fileInput!: ElementRef;
    isSending = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['messageList']) {
            this.shouldScrollToBottom = true;
        }
    }

    ngAfterViewChecked() {
        if (this.shouldScrollToBottom) {
            this.scrollToBottom();
            this.shouldScrollToBottom = false;
        }
    }

    sendNewMessage() {
        if (this.newMessage.trim() !== '' || this.thumbnails.length > 0) {
            this.isSending = true;

            this.onSendMessage(this.newMessage.trim(), this.selectedFiles, this.projectId)?.then(() => {
                this.shouldScrollToBottom = true;
                this.isSending = false;
            });

            this.resetInputs();

        }
    }

    private resetInputs() {
        this.newMessage = '';
        this.thumbnails = [];
        this.selectedFiles = [];
    }

    scrollToBottom() {
        setTimeout(() => {
            if (this.messageContainer) {
                const container = this.messageContainer.nativeElement;
                container.scrollTop = container.scrollHeight;
            }
        }, 100);
    }

    initCamera() {
        this.onCameraButtonClick();
    }

    onFileSelected() {
        const files: any[] = Array.from(this.fileInput.nativeElement.files);
        this.thumbnails = [];
        this.selectedFiles = []; // Clear the selectedFiles array

        const promises = files.map(file => {
            if (file.type.startsWith('video')) {
                return this.createVideoThumbnail(file).then(thumbnail => {
                    this.thumbnails.push(thumbnail);
                    this.selectedFiles.push(file);
                });
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                return new Promise((resolve, reject) => {
                    reader.onload = () => {
                        this.thumbnails.push(reader.result as string);
                        this.selectedFiles.push(file);
                        resolve(undefined);
                    };

                    reader.onerror = reject;
                });
            }
        });

        Promise.all(promises).catch(err => console.error(err)); // Handle errors with promise fulfillment here
    }

    removeThumbnail(thumb: string) {
        const index = this.thumbnails.indexOf(thumb);
        if (index !== -1) {
            this.thumbnails.splice(index, 1);
        }
    }

    createVideoThumbnail(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                video.currentTime = 5; // we seek to the 5s time in the video
            };

            video.onseeked = () => { // event will be fired when the seeking is done.
                ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                const thumbnail = canvas.toDataURL('image/jpeg');
                URL.revokeObjectURL(video.src);
                resolve(thumbnail);
            };

            video.onerror = reject;
        });
    }
}
