import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom, debounceTime, distinctUntilChanged, Subject, interval } from 'rxjs';
import {
  ConversationControllerService,
  MessageDto,
  ConversationsResponseDto,
  CommunicationUserDto,
  ConversationResponseDto
} from '@brody-bookings/api-v2';
import { LanguageService } from '../../../../service/language.service';
import { MessageService, SelectItemGroup, TreeNode } from 'primeng/api';
import { AuthenticationService } from '../../../../../layout/service/app.auth.service';
import { WidgetConversationService } from './_service/widget.conversation.service';
import { ConfirmModalService } from '../../../../service/confirmModal.service';
import { MessageNotificationService } from '../../../../service/message-notification.service';
import { ConversationMessagesDto } from 'libs/api-typescript-angular/src/model/conversationMessagesDto';

interface ProjectConversation {
  projectId: string;
  name: string | null;
  producerOauthId: string | null;
  producerName: string;
  totalNumberOfMessages: number;
  roles: {
    roleId: string;
    name: string;
    participants: { name: string | null; oauthId: string, numberOfNewMessages: number, totalNumberOfMessages: number, conversationId: string }[];
    isExpanded?: boolean;
  }[];
  additionalParticipants: { name: string | null; oauthId: string, numberOfNewMessages: number, totalNumberOfMessages: number, conversationId: string }[];
  isExpanded?: boolean;
}

interface SearchResult {
  name: string;
  oauthId: string;
}

export interface MessageDtoWithDeletedFlag extends MessageDto {
  isDeleted?: boolean;
}

@Component({
  selector: 'app-conversation-widget',
  templateUrl: './conversation.widget.component.html',
  styleUrls: ['./conversation.widget.component.scss']
})
export class ConversationWidgetComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  conversations: ConversationsResponseDto | undefined;
  directConversations: any[] = [];
  projectConversations: ProjectConversation[] = [];
  knownMessageCounts: { [conversationId: string]: number } = {};

  isBroadCast = false;
  selectedReceiverConversationId: string | undefined = undefined;
  messageList: MessageDtoWithDeletedFlag[] = [];

  selectedConversation: any | null = null;
  selectedConversationId: string | null = null;
  selectedRecipient: string | null = null;
  selectedProjectId: string | undefined = undefined;
  currentUserOauthId = '';
  // Models

  showNoAccountMessage: boolean = false;

  selectedParticipantId: string | null = null;

  searchQuery: string = '';
  searchResults: any[] = [];
  private directSearchSubject = new Subject<string>();
  private projectSearchSubject = new Subject<string>();

  filteredDirectConversations: any[] = [];
  showSearchResults: boolean = false;

  isLoading: boolean = true;

  projectSearchQuery: string = '';
  filteredProjectConversations: ProjectConversation[] = [];

  showBroadcastInterface: boolean = false;
  broadcastMessage: string = '';
  selectedRole: any;
  selectedProjectIdForBroadcast: string | undefined;

  constructor(
    private router: Router,
    private conversationControllerService: ConversationControllerService,
    private languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private widgetConversationService: WidgetConversationService,
    private messageService: MessageService,
    private confirmModalService: ConfirmModalService,
    private messageNotificationService: MessageNotificationService
  ) {

  }

  ngOnInit(): void {
    // this.loadModels();
    // this.loadProjectRelevantPersons('433279b3-c778-4bb2-b7da-58af625d617d');
    this.initCurrentUser();
    this.loadConversations();
    this.startCheckingNewMessages();

    this.setupDirectSearch();
    this.setupProjectSearch();

    this.messageNotificationService.updateCheckInBackground(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.directSearchSubject.complete();
    this.projectSearchSubject.complete();
    this.messageNotificationService.updateCheckInBackground(true);
  }

  loadConversations() {
    this.isLoading = true;
    firstValueFrom(this.conversationControllerService.getBookerConversations())
      .then((conversations: any) => {
        this.conversations = conversations;
        this.directConversations = conversations.directConversations || [];
        this.filteredDirectConversations = this.directConversations;
        this.projectConversations = (conversations.projectConversations || []).map((conv: ProjectConversation) => ({
          ...conv,
          isExpanded: false
        }));
        this.filteredProjectConversations = this.projectConversations;

        this.knownMessageCounts = this.directConversations.reduce((acc: any, curr: any) => ({ ...acc, [curr.conversationId]: curr.totalNumberOfMessages }), {});
        this.projectConversations.forEach(pc => {
          pc.roles.forEach(r => {
            r.participants.forEach(p => {
              if (p.conversationId) {
                this.knownMessageCounts[p.conversationId] = p.totalNumberOfMessages;
              }
            })
          })
        });

      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  startCheckingNewMessages() {
    this.subscriptions.push(interval(10000) // Check every 30 seconds
      .subscribe(() => {
        this.checkNewMessages();
      }));
  }

  checkNewMessages() {
    this.conversationControllerService.checkNewMessages(this.knownMessageCounts).subscribe(
      (result: string[]) => {
        if (result.length > 0) {
          this.updateSpecificConversations(result);
        }
      },
      (error: any) => {
        console.error('Error checking for new messages:', error);
      }
    );
  }

  updateSpecificConversations(conversationIds: string[]) {
    this.conversationControllerService.fetchConversations(conversationIds).subscribe(
      (conversations: ConversationMessagesDto[]) => {
        firstValueFrom(this.messageNotificationService.newMessagesCount$).then((count) => {
          this.messageNotificationService.updateNewMessagesCount(count + conversations.map((c) => c.newMessages?.length || 0).reduce((a, b) => a + b, 0));
        });
        
        conversations.forEach((conversation) => {
          const directConversation = this.directConversations.find(dc => dc.conversationId === conversation.conversationId);

          if (directConversation) {
            // Update existing direct conversation
            directConversation.numberOfNewMessages = conversation.newMessages?.length || 0;
            if (this.selectedConversationId === conversation.conversationId) {
              this.messageList = [
                ...this.messageList,
                ...(conversation.newMessages?.map(msg => ({...msg, isDeleted: false})) || [])
              ];
            }
            this.knownMessageCounts[conversation.conversationId ?? ''] = conversation.totalNumberOfMessages || 0;
          } else if (conversation.projectId && conversation.roleId) {

            // Handle project conversation
            let projectConversation = this.projectConversations.find(pc => pc.projectId === conversation.projectId);

            if (!projectConversation) {
              return;
            }

            let role = projectConversation.roles.find(r => r.roleId === conversation.roleId);

            if (!role) {
              return;
            }

            let participant = role.participants.find(p => p.oauthId === conversation.participantOauthId);

            if (!participant) {
              return;
            }

            // Update participant data
            participant.numberOfNewMessages = conversation.newMessages?.length || 0;
            participant.totalNumberOfMessages = conversation.totalNumberOfMessages || 0;
            participant.conversationId = conversation.conversationId || '';
            this.knownMessageCounts[participant.conversationId] = conversation.totalNumberOfMessages || 0;
          } else {
            // Handle new direct conversation
            const newDirectConversation = {
              conversationId: conversation.conversationId,
              name: conversation.title || 'Unknown',
              participantOauthId: conversation.participantOauthId,
              lastMessage: conversation.newMessages?.[0]?.message || '',
              lastMessageTimeStamp: conversation.newMessages?.[0]?.timeStamp || new Date(),
              hasNewMessages: true,
              numberOfNewMessages: conversation.newMessages?.length || 0,
              otherParticipantName: conversation.otherParticipantName
            };
            this.directConversations.push(newDirectConversation);
            this.knownMessageCounts[conversation.conversationId ?? ''] = conversation.totalNumberOfMessages || 0;
          }
        });

        // After processing all conversations, sort directConversations by lastMessageTimeStamp
        this.directConversations.sort((a, b) => 
          new Date(b.lastMessageTimeStamp).getTime() - new Date(a.lastMessageTimeStamp).getTime()
        );

      },
      (error: any) => {
        console.error('Error updating specific conversations:', error);
      }
    );
  }

  toggleProjectConversation(conversation: ProjectConversation) {
    conversation.isExpanded = !conversation.isExpanded;
  }

  toggleRole(role: ProjectConversation['roles'][0]) {
    role.isExpanded = !role.isExpanded;
  }

  selectConversation(conversation: any) {
    this.showNoAccountMessage = false;
    this.selectedConversation = conversation;
    this.selectedConversationId = conversation.conversationId;
    this.selectedProjectId = undefined;
    this.selectedParticipantId = null;
    this.loadMessagesForConversation(conversation.conversationId, undefined, undefined)
      .then(() => {
        // Mark messages as read
        this.markMessagesAsRead(conversation.conversationId);
      })
      .catch((error) => {
        console.error('Error loading messages:', error);
      });
  }

  selectParticipant(participant: any, projectId: string | undefined, roleId?: string) {
    this.showBroadcastInterface = false;
    this.selectedProjectId = projectId;
    this.selectedConversation = null;
    this.selectedConversationId = null;
    this.selectedParticipantId = null;
    if (participant.oauthId) {
      this.showNoAccountMessage = false;
      this.selectedParticipantId = participant.oauthId;
      this.loadMessagesForConversation(participant.oauthId, projectId, roleId);
    } else {
      this.showNoAccountMessage = true;
      this.selectedConversation = null;
      this.selectedParticipantId = null;
    }
  }

  private loadMessagesForConversation(participantIdOrConversationId: string, projectId: string | undefined, roleId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isConversationId(participantIdOrConversationId)) {
        // For direct conversations
        firstValueFrom(this.conversationControllerService.getMessagesByConversationId(participantIdOrConversationId))
          .then((messages: any) => {
            this.messageList = messages.map((m:any) => {
              if (m.message === 'Message deleted') {
                return {...m, isDeleted: true};
              }
              return m;
            });
            this.selectedReceiverConversationId = participantIdOrConversationId;
            resolve();
          })
          .catch((error) => {
            console.error('Error loading messages:', error);
            this.messageList = [];
            this.selectedReceiverConversationId = undefined;
            reject(error);
          });
      } else {
        // For project-related conversations
        firstValueFrom(this.conversationControllerService.getConversation({
          senderId: this.currentUserOauthId,
          participantIds: [participantIdOrConversationId],
          projectId: projectId,
          roleId: roleId
        }))
          .then((conversation: any) => {
            this.selectedReceiverConversationId = conversation.conversationId.value;
            this.messageList = (conversation.messages || []).map((m: any) => ({
              messageId: m.messageId,
              senderId: m.senderId,
              senderName: m.senderName,
              message: m.message,
              imageUrl: m.imageUrl,
              videoUrl: m.videoUrl,
              timeStamp: m.timeStamp,
              formattedTimeStamp: m.formattedTimeStamp,
              isDeleted: m.message === 'Message deleted'
            }));
            this.selectedConversation = {
              conversationId: conversation.conversationId.value,
              name: conversation.participantName || 'Unknown',
              lastMessage: conversation.messages[conversation.messages.length - 1]?.message || '',
              lastMessageTimeStamp: conversation.messages[conversation.messages.length - 1]?.timeStamp || new Date(),
              hasNewMessages: false
            };
            this.selectedConversationId = conversation.conversationId.value;
            this.markMessagesAsRead(conversation.conversationId.value, projectId, roleId, participantIdOrConversationId);
            resolve();
          })
          .catch((error) => {
            console.error('Error loading messages:', error);
            if (error.status === 404) {
              this.messageList = [];
              this.selectedReceiverConversationId = undefined;
              this.selectedConversation = null;
              this.selectedConversationId = null;
            }
            reject(error);
          });
      }
    });
  }

  private isConversationId(id: string): boolean {
    // Implement logic to determine if the id is a conversation id
    // This could be based on the format of your ids or other criteria
    // For example, if conversation ids are always UUIDs:
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(id);
  }

  onSendMessage(newMessage: string, images: Array<Blob>, projectId?: string) {
    if (this.isBroadCast) {
      return this.sendBroadCastMessage(this.selectedRecipient!!, newMessage, images, projectId);
    } else {
      return this.sendDirectMessage(this.selectedRecipient!!, newMessage, images, projectId);
    }

  }


  onDeleteMessage(messageId: string) {
    if (!this.selectedReceiverConversationId) return;

    const conversationId = this.selectedReceiverConversationId;
    this.confirmModalService.showConfirmModal('Willst du diese Nachricht wirklich löschen?', () => {
      this.widgetConversationService.deleteMessage(conversationId, messageId).then(() => {
        // Find the message and update its text instead of removing it
        const messageIndex = this.messageList.findIndex(m => m.messageId === messageId);
        if (messageIndex !== -1) {
          this.messageList[messageIndex] = {
            ...this.messageList[messageIndex],
            message: "Message deleted",
            // Optionally, you can add a flag to indicate it's a deleted message
            isDeleted: true
          };
          // Trigger change detection
          this.messageList = [...this.messageList];
        }
      });
    });
  }

  onRefreshMessages() {
    if (!this.selectedReceiverConversationId) return;

    const conversationId = this.selectedReceiverConversationId;
    this.widgetConversationService.refreshMessages(conversationId).then((messages: unknown) => {
      this.messageList = messages as MessageDtoWithDeletedFlag[];
    });
  }

  onCameraButtonClick() {
    console.log('hallo');
  }

  private initCurrentUser() {
    this.subscriptions.push(this.authenticationService.frontendUser$.subscribe((currentUser) => {
      if (!currentUser) return;
      this.currentUserOauthId = currentUser.userId;
    }));
  }


  private resetMessages() {
    this.messageList = [];
    this.selectedReceiverConversationId = undefined;
  }

  private async sendDirectMessage(oauthId: string, newMessage: string, images: Array<Blob>, projectId?: string) {
    return this.widgetConversationService.sendMessage(oauthId, newMessage, false, images, this.selectedReceiverConversationId, projectId).then((response: any) => {
      response.messages?.forEach((message: any) => {
        this.messageList.push(message);
      });
      
      // Increase the knownMessageCount for the selected conversation
      if (this.selectedReceiverConversationId) {
        this.knownMessageCounts[this.selectedReceiverConversationId] = (this.knownMessageCounts[this.selectedReceiverConversationId] || 0) + 1;
      }
    });
  }

  private sendBroadCastMessage(oauthIds: string, newMessage: string, images: Array<Blob>, projectId?: string, roleId?: string) {
    return this.widgetConversationService.sendMessage(oauthIds, newMessage, true, [], this.selectedReceiverConversationId, projectId, roleId).then((response: any) => {
      response.messages?.forEach((message: any) => {
        this.messageList.push(message);
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Nachricht erfolgreich versendet',
        detail: 'Nachricht an alle Teilnehmer gesendet'
      });
    });
  }

  private setupDirectSearch(): void {
    this.directSearchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((query) => {
      this.performDirectSearch(query);
    });
  }

  async onSearch() {
    if (this.searchQuery && this.searchQuery.length > 0) {
      try {
        // Filter existing conversations based on the search query
        const filteredExistingConversations = this.filteredDirectConversations.filter(conv =>
          conv.otherParticipantName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );

        // Wait for the API results
        const apiResults = await firstValueFrom(this.conversationControllerService.searchUsers(this.searchQuery));

        // Add this type assertion
        const typedApiResults = apiResults as CommunicationUserDto[];

        // Combine filtered existing conversations with API results
        this.searchResults = [
          ...filteredExistingConversations.map(conv => ({
            name: conv.name,
            firstName: conv.firstName,
            lastName: conv.lastName,
            oauthId: conv.oauthId,
            roles: conv.roles,
            conversationId: conv.conversationId
          })),
          ...typedApiResults.filter((result) => 
            !filteredExistingConversations.some(conv => 
              conv.name === result.formattedNameWithRoles
            )
          ).map((result) => ({
            name: result.formattedNameWithRoles,
            firstName: result.firstName,
            lastName: result.lastName,
            oauthId: result.oauthId,
            roles: result.roles
          }))
        ];

      } catch (error) {
        console.error('Error searching users:', error);
        this.searchResults = [];
      }
    } else {
      this.searchResults = [];
    }
  }

  isExistingConversation(result: any): boolean {
    return this.filteredDirectConversations.some(conv => 
      conv.otherParticipantName === result.name
    );
  }

  private performDirectSearch(query: string): void {
    if (query.length < 2) {
      this.filteredDirectConversations = this.directConversations;
      this.searchResults = [];
      this.showSearchResults = false;
      return;
    }

    // Filter existing direct conversations
    this.filteredDirectConversations = this.directConversations.filter(conv =>
      conv.name.toLowerCase().includes(query.toLowerCase())
    );

    // If no results in direct conversations, call the API
    if (this.filteredDirectConversations.length === 0) {
      this.conversationControllerService.searchUsers(query).subscribe(
        (results: CommunicationUserDto[]) => {
          this.searchResults = results;
          this.showSearchResults = true;
        },
        (error: any) => {
          console.error('Error searching users:', error);
          this.searchResults = [];
          this.showSearchResults = false;
        }
      );
    } else {
      this.searchResults = [];
      this.showSearchResults = false;
    }
  }

  selectSearchResult(result: any): void {
    if (result.conversationId) {
      // It's an existing conversation
      this.selectConversation(result);
    } else {
      // It's a new user from API search
      console.log('this.directConversations', this.directConversations)
      console.log('result', result);
      const existingConversation = this.directConversations.find(
        conv => conv.otherParticipantName == result.name
      );

      console.log('existingConversation', existingConversation);

      if (existingConversation) {
        this.selectConversation(existingConversation);
      } else {
        this.createNewConversation(result);
      }
    }

    this.searchQuery = '';
    this.filteredDirectConversations = this.directConversations;
    this.searchResults = [];
    this.showSearchResults = false;
  }

  private createNewConversation(participant: CommunicationUserDto): void {
    this.conversationControllerService.createConversation('de', {
      conversationTitle: participant.firstName + ' ' + participant.lastName + ' (' + this.getRole(participant.roles) + ')',
      participantIds: [participant.oauthId!!]
    }).subscribe(
      (newConversation: ConversationResponseDto) => {
        const formattedConversation = {
          conversationId: newConversation.conversationId,
          name: newConversation.conversationTitle,
          participantOauthId: participant.oauthId,
          lastMessage: '',
          lastMessageTimeStamp: newConversation.lastEditedAt,
          hasNewMessages: false,
          otherParticipantName: newConversation.conversationTitle
        };
        this.directConversations.unshift(formattedConversation);
        this.selectConversation(formattedConversation);
      },
      (error: any) => {
        console.error('Error creating new conversation:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to create new conversation. Please try again.'
        });
      }
    );
  }

  private setupProjectSearch(): void {
    this.projectSearchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      this.performProjectSearch();
    });
  }

  onProjectSearch(): void {
    this.projectSearchSubject.next(this.projectSearchQuery);
  }

  private performProjectSearch(): void {
    if (this.projectSearchQuery.length === 0) {
      this.filteredProjectConversations = this.projectConversations;
      return;
    }

    const query = this.projectSearchQuery.toLowerCase();
    this.filteredProjectConversations = this.projectConversations.filter(conv =>
      conv.producerName.toLowerCase().includes(query) ||
      conv.name?.toLowerCase().includes(query)
    );
  }

  openBroadcastInterface(role: any, projectId: string | undefined) {
    this.selectedRole = role;
    this.selectedProjectIdForBroadcast = projectId;
    this.showBroadcastInterface = true;
    this.broadcastMessage = '';
    this.selectedConversation = null;
    this.selectedConversationId = null;
    this.selectedParticipantId = null;
    this.showNoAccountMessage = false;
    this.selectedReceiverConversationId = undefined;
  }

  sendBroadcastMessage() {
    if (!this.broadcastMessage.trim()) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a message' });
      return;
    }

    const participantIds = this.selectedRole.participants
      .filter((participant: any) => participant.oauthId)
      .map((participant: any) => participant.oauthId);

    if (participantIds.length === 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No valid participants found' });
      return;
    }

    this.sendBroadCastMessage(
      participantIds.join(','),
      this.broadcastMessage,
      [],
      this.selectedProjectIdForBroadcast,
      this.selectedRole.roleId
    ).then(() => {
      //this.showBroadcastInterface = false;
      this.broadcastMessage = '';
    }).catch(error => {
      console.error('Error sending broadcast message:', error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send broadcast message' });
    });
  }

  // Add this new method
  private markMessagesAsRead(conversationId: string, projectId?: string, roleId?: string, participantIdOrConversationId?: string): void {
    this.conversationControllerService.autoUpdateTracking(conversationId).subscribe(
      () => {
        const updatedConversation = this.directConversations.find(c => c.conversationId === conversationId);
        if (updatedConversation) {
          updatedConversation.numberOfNewMessages = 0;
        } else {
          if (projectId && roleId && participantIdOrConversationId) {
            const projectConversation = this.projectConversations.find(pc => pc.projectId === projectId);
            if (projectConversation) {
              const role = projectConversation.roles.find(r => r.roleId === roleId);
              if (role) {
                const participant = role.participants.find(p => p.oauthId === participantIdOrConversationId);
                if (participant) {
                  participant.numberOfNewMessages = 0;
                }
              }
            }
          }
        }

        // Calculate total number of new messages across all conversations
        const directConversationTotalMessages = this.directConversations.reduce((sum, conv) => sum + (conv.numberOfNewMessages || 0), 0);
        let projectConversationTotalMessages = 0;
        this.projectConversations.forEach(pc => {
          pc.roles.forEach(r => {
            r.participants.forEach(p => {
              projectConversationTotalMessages += p.numberOfNewMessages || 0;
            })
          })
        });
    
        const totalNewMessages = directConversationTotalMessages + projectConversationTotalMessages;
          
        // Update the message notification service
        this.messageNotificationService.updateNewMessagesCount(totalNewMessages);
      },
      (error: any) => {
        console.error('Error marking messages as read:', error);
      }
    );
  }

  getRole(roles?: string) {
    if (!roles) return undefined;
    return roles.split(',').map((role) => role.charAt(0).toUpperCase()).join(', ');
  }

  getTotalParticipants(conversation: any): number {
    const roleParticipants = conversation.roles.reduce((sum: number, role: any) => sum + role.participants.length, 0);
    return roleParticipants + conversation.additionalParticipants.length;
  }

  getRoleNewMessageCount(role: any): number {
    return role.participants.reduce((sum: number, participant: any) => sum + (participant.numberOfNewMessages || 0), 0);
  }

  getProjectNewMessageCount(conversation: ProjectConversation): number {
    let totalNewMessages = 0;
    
    // Sum new messages from roles
    conversation.roles.forEach(role => {
      totalNewMessages += this.getRoleNewMessageCount(role);
    });
    
    // Sum new messages from additional participants
    conversation.additionalParticipants.forEach(participant => {
      totalNewMessages += participant.numberOfNewMessages || 0;
    });
    
    return totalNewMessages;
  }
}
