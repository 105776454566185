/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectAdditionalParticipant
 */
export interface ProjectAdditionalParticipant {
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    customerSuffix?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    customerStreet: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    customerZipCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    customerCity: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    employeeFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    employeeLastName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectAdditionalParticipant
     */
    hasAdminRights: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    employeeEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    employeePhone: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    employeeMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    oauthId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipant
     */
    mobile?: string;
}

/**
 * Check if a given object implements the ProjectAdditionalParticipant interface.
 */
export function instanceOfProjectAdditionalParticipant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "customerName" in value;
    isInstance = isInstance && "customerStreet" in value;
    isInstance = isInstance && "customerZipCode" in value;
    isInstance = isInstance && "customerCity" in value;
    isInstance = isInstance && "employeeId" in value;
    isInstance = isInstance && "employeeFirstName" in value;
    isInstance = isInstance && "employeeLastName" in value;
    isInstance = isInstance && "hasAdminRights" in value;
    isInstance = isInstance && "employeeEmail" in value;
    isInstance = isInstance && "employeePhone" in value;

    return isInstance;
}

export function ProjectAdditionalParticipantFromJSON(json: any): ProjectAdditionalParticipant {
    return ProjectAdditionalParticipantFromJSONTyped(json, false);
}

export function ProjectAdditionalParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAdditionalParticipant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'customerName': json['customerName'],
        'customerSuffix': !exists(json, 'customerSuffix') ? undefined : json['customerSuffix'],
        'customerStreet': json['customerStreet'],
        'customerZipCode': json['customerZipCode'],
        'customerCity': json['customerCity'],
        'employeeId': json['employeeId'],
        'employeeFirstName': json['employeeFirstName'],
        'employeeLastName': json['employeeLastName'],
        'hasAdminRights': json['hasAdminRights'],
        'employeeEmail': json['employeeEmail'],
        'employeePhone': json['employeePhone'],
        'employeeMobile': !exists(json, 'employeeMobile') ? undefined : json['employeeMobile'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'suffix': !exists(json, 'suffix') ? undefined : json['suffix'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
    };
}

export function ProjectAdditionalParticipantToJSON(value?: ProjectAdditionalParticipant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerName': value.customerName,
        'customerSuffix': value.customerSuffix,
        'customerStreet': value.customerStreet,
        'customerZipCode': value.customerZipCode,
        'customerCity': value.customerCity,
        'employeeId': value.employeeId,
        'employeeFirstName': value.employeeFirstName,
        'employeeLastName': value.employeeLastName,
        'hasAdminRights': value.hasAdminRights,
        'employeeEmail': value.employeeEmail,
        'employeePhone': value.employeePhone,
        'employeeMobile': value.employeeMobile,
        'username': value.username,
        'suffix': value.suffix,
        'oauthId': value.oauthId,
        'mobile': value.mobile,
    };
}

