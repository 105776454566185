/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversationInfo
 */
export interface ConversationInfo {
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    conversationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    lastMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof ConversationInfo
     */
    numberOfNewMessages?: number;
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    lastMessageTimeStamp?: string;
    /**
     * 
     * @type {number}
     * @memberof ConversationInfo
     */
    totalNumberOfMessages?: number;
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    otherParticipantName?: string;
}

/**
 * Check if a given object implements the ConversationInfo interface.
 */
export function instanceOfConversationInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversationInfoFromJSON(json: any): ConversationInfo {
    return ConversationInfoFromJSONTyped(json, false);
}

export function ConversationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversationId': !exists(json, 'conversationId') ? undefined : json['conversationId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastMessage': !exists(json, 'lastMessage') ? undefined : json['lastMessage'],
        'numberOfNewMessages': !exists(json, 'numberOfNewMessages') ? undefined : json['numberOfNewMessages'],
        'lastMessageTimeStamp': !exists(json, 'lastMessageTimeStamp') ? undefined : json['lastMessageTimeStamp'],
        'totalNumberOfMessages': !exists(json, 'totalNumberOfMessages') ? undefined : json['totalNumberOfMessages'],
        'otherParticipantName': !exists(json, 'otherParticipantName') ? undefined : json['otherParticipantName'],
    };
}

export function ConversationInfoToJSON(value?: ConversationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversationId': value.conversationId,
        'name': value.name,
        'lastMessage': value.lastMessage,
        'numberOfNewMessages': value.numberOfNewMessages,
        'lastMessageTimeStamp': value.lastMessageTimeStamp,
        'totalNumberOfMessages': value.totalNumberOfMessages,
        'otherParticipantName': value.otherParticipantName,
    };
}

